import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import UIkit from "uikit";
import { checkForRedirect } from "utils/ad_block";
import { usePersistState } from "utils/helpers";

const AdBlockModalPortal = props => {
  const modalRoot = document.createElement('div');
  modalRoot.setAttribute('uk-modal', 'bg-close: false');
  modalRoot.id = 'adblock-notice';

  useEffect(() => {
    document.body.appendChild(modalRoot);
    return () => {
      document.body.removeChild(modalRoot);
    }
  });

  return ReactDOM.createPortal(props.children, modalRoot);
} 

const DetectAdBlock: React.FC<{}> = () => {
  const [adBlockDetected, setAdBlockDetected] = usePersistState("adBlockDetected", false);
  const [showAdBlockPopup, setShowAdBlockPopup] = usePersistState("showAdBlockPopup", true);

  const notDetected = () => {
    setAdBlockDetected(false);
    setShowAdBlockPopup(false);
  }

  const detected = () => {
    setAdBlockDetected(true);
    if(checkForRedirect(window.location) && document.getElementById('adblock-notice')) {
      UIkit.modal('#adblock-notice').show();
    }
  }
  
  const checkForAdblock = () => {
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.id = 'adblock-detection';
    script.type = 'text/javascript';
    script.src = '/advertisers.js';
    script.onload = notDetected;
    script.onerror = detected;
    head.appendChild(script);
  }

  useEffect(() => {
    checkForAdblock()
  });
  

  return showAdBlockPopup && (
    <AdBlockModalPortal>
      <div className="uk-modal-dialog">
        <div className="uk-modal-body">
          <h2 className="uk-modal-title">Uh oh. Looks like you're using an ad blocker.</h2>
          <p>Ad blockers will block our ad-related product pages.</p>
          <p>Please whitelist <b>edo.com</b> or disable your ad blocker on this site to view our content.</p>
        </div>
        <div className="uk-modal-footer uk-text-right">
          <button className="uk-button uk-button-default uk-modal-close" type="button" onClick={() => setShowAdBlockPopup(false)}>Close</button>
        </div>
      </div>
    </AdBlockModalPortal>
  );
};

export default DetectAdBlock;
