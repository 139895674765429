/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/styles/global.css'

import React from 'react';
import mixpanel from 'mixpanel-browser';
import DetectAdBlock from "components/detect_ad_block";

import { MixPanelContext } from './src/context/mixpanel_context';
import { checkForRedirect } from 'utils/ad_block';

export const wrapRootElement = ({ element }) => {
  // const mixpanel_token = process.env.GATSBY_EDO_ENV === 'production' ? '8d9427d70520978406e32fac9b1242f3' : '6943eb3acc944ff4f18b90ce5e5538ff';
  const mixpanel_token = "7068fc84c09fc3af8cb225c446adfead";
  console.log("EDO environment: " + process.env.GATSBY_EDO_ENV);
  mixpanel.init(mixpanel_token);
  mixpanel.set_config({ignore_dnt: true});

  if (process.env.GATSBY_EDO_ENV !== 'production') {
    mixpanel.disable();
  }

  return (
    <MixPanelContext.Provider value={mixpanel}>
      {element}
      {<DetectAdBlock />}
    </MixPanelContext.Provider>
  );
};

/* 
  Check for Ad Block and reroute to /blocked
  Uses prevLocation because Ad Blockers reroute away from blocked pages
  making the prevLocation our blocked page to check for redirect.
*/

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  if(window.localStorage.getItem("adBlockDetected") === "true" && prevLocation) {
    checkForRedirect(prevLocation);
  }
}
