import { useEffect, useState } from 'react';
import { cloudfrontImageBaseUrl } from "utils/constants";

export const getCloudFrontImageUrl = (path: string): string => `${cloudfrontImageBaseUrl}/${path}`;

export const usePersistState = (key: string | any, defaultValue: string | boolean | any) => {
  const [value, setValue] = useState(() => {
    if (typeof window !== 'undefined') {
      const persistedState = window.localStorage.getItem(key);
      return persistedState !== null ? JSON.parse(persistedState) : defaultValue;
    }
  });

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
};
