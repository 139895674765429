export const adEngagePath = "/ad-engage/brand-rankings-tv-ad-creatives";

export const env = process.env.GATSBY_EDO_ENV === "production" ? "production" : "staging";
export const cloudfrontImageBaseUrl = `https://staging-marketing.edo.com/v2/images`
export const dataLoadingPath = process.env.GATSBY_EDO_ENV === "production" ? "https://production-marketing.edo.com/data/" :"https://staging-marketing.edo.com/data/";

export const topEventsPath = process.env.GATSBY_EDO_ENV === "production" ? "https://production-marketing.edo.com/top_events/" :"https://staging-marketing.edo.com/top_events/";

export const topAdsPath = process.env.GATSBY_EDO_ENV === "production" ? "https://production-marketing.edo.com/top_ads/" :"https://staging-marketing.edo.com/top_ads/";


export const TOP_EVENTS = {
  nfl: {
    title: "2023 NFL Season",
    path: "nfl-top-ads",
    sort: "Performance",
    header: "NFL Creatives"
  },
  winterOlympics: {
    title: "2022 Beijing Winter Olympics",
    path: "2022_beijing_winter_olympics",
    sort: "Airings",
    header: "2022 Beijing Winter Olympics"
  },
  worldCup: {
    title: "2022 Men's World Cup",
    path: "world-cup",
    sort: "Performance",
    header: "Men's World Cup Creatives"
  },
  womensWorldCup: {
    title: "2023 Women's World Cup",
    path: "womens-world-cup",
    sort: "Performance",
    header: "Women's World Cup Creatives"
  },
  marchMadness: {
    title: "2024 Men's March Madness",
    path: "march-madness",
    sort: "Performance",
    header: "Men's March Madness Creatives"
  },
  womensMarchMadness: {
    title: "2024 Women's March Madness",
    path: "womens-march-madness",
    sort: "Performance",
    header: "Women's March Madness Creatives"
  }
}

export const CURRENT_TOP_EVENT_KEY = "nfl"


// random account paths
export const TOP_ADS_ACCOUNTS = {
  RnPMYLw: {
    title: "L'Oreal",
    path: "loreal",
    categories: ["cpg_personal_care"]
  },
  JQeuriF: {
    title: "Procter & Gamble",
    path: "pg",
    categories: ["cpg_home_and_family", "cpg_personal_care", "cpg_health"]
  },
  IIkBJHL: {
    title: "PepsiCo",
    path: "pepsi-co",
    categories: ["cpg_food", "cpg_beverage"]
  },
  hcqqIJS: {
    title: "Unilever",
    path: "unilever",
    categories: ["cpg_home_and_family", "cpg_personal_care"]
  },
  ZLFakGB: {
    title: "Coca Cola",
    path: "coca-cola",
    categories: ["cpg_food", "cpg_beverage"]
  },
  kchtQRT: {
    title: "Kimberly Clark",
    path: "kimberly-clark",
    categories: ["cpg_home_and_family"]
  },
  GkyNduK: {
    title: "Clorox",
    path: "clorox",
    categories: ["cpg_home_and_family", "cpg_personal_care", "cpg_food"]
  },
  Aokqqst: {
    title: "Mondelez",
    path: "mondelez",
    categories: ["cpg_food"]
  },
  wYpWxjf: {
    title: "Kraft Heinz",
    path: "kraft-heinz",
    categories: ["cpg_food"]
  }
}

export const TOP_ADS_CATEGORY_NAMES = {
  cpg_personal_care: "Consumer Packaged Goods: Personal Care",
  cpg_home_and_family: "Consumer Packaged Goods: Home & Family",
  cpg_health: "Consumer Packaged Goods: OTC Health",
  cpg_food: "Consumer Packaged Goods: Food",
  cpg_beverage: "Consumer Packaged Goods: Beverage",
}

